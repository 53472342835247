import React, { useState } from 'react';
import { LoginPage } from './components/LoginPage';
import { OpinionsAnalyzer } from './components/OpinionsAnalyzer';
import { OpinionsStats } from './components/OpinionsStats';
import { MantineProvider } from '@mantine/core';
import { Notifications } from '@mantine/notifications';

function App() {
  const [token, setToken] = useState<string | null>(localStorage.getItem('token'));
  const [activeView, setActiveView] = useState<'login' | 'analyzer' | 'stats'>(
    window.location.pathname === '/statistics' ? 'stats' : token ? 'analyzer' : 'login'
  );

  const handleLogin = (newToken: string) => {
    localStorage.setItem('token', newToken);
    setToken(newToken);
    setActiveView('analyzer');
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    setToken(null);
    setActiveView('login');
  };

  if (activeView === 'stats') {
    return <OpinionsStats isPublic={true} />;
  }

  if (!token) {
    return (
      <MantineProvider withGlobalStyles withNormalizeCSS>
        <Notifications />
        <div>
          <nav className="navbar" role="navigation">
            <div className="navbar-end">
              <div className="navbar-item">
                <div className="buttons">
                  <button 
                    className="button is-info" 
                    onClick={() => setActiveView('stats')}
                  >
                    Statystyki
                  </button>
                </div>
              </div>
            </div>
          </nav>
          <LoginPage onLogin={handleLogin} />
        </div>
      </MantineProvider>
    );
  }

  return (
    <MantineProvider withGlobalStyles withNormalizeCSS>
      <Notifications />
      <OpinionsAnalyzer 
        onLogout={handleLogout} 
        token={token}
        onViewStats={() => setActiveView('stats')}
      />
    </MantineProvider>
  );
}

export default App; 